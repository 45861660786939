import React from 'react'
import sAndMeLogo from '../assets/sandme-logo.svg'
import { CONTACT } from '../constant'
import logo from '../assets/sandme-logo.svg'
import facebook from '../assets/facebook.svg'
import linkedin from '../assets/linkedIn.svg'
import insta from '../assets/instagram.svg'

import mapIcon from '../assets/mapIcon.svg'
import { Link } from 'gatsby'

const Footer = (props) => {
  return (
    <footer className="container-fluid mt-auto" style={{ background: '#FBFAF9' }}>
      <div className="container-fluid content-container mb-7 mt-7">
        <div className="row" style={{}}>
          <div className="col-12 mb-7">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 col-md-6">
            <div className="row" style={{}}>
              <div className="col-6">
                <img className="mb-5" src={mapIcon} alt="" />
                <a
                  href="https://www.google.com/maps/place/Hauser+Pl.+20,+1127+K%C3%B8benhavn/@55.6830087,12.5761125,17z/data=!3m1!4b1!4m5!3m4!1s0x4652531af36bde75:0x53d8bd631e9a986f!8m2!3d55.6830087!4d12.5761125"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="mb-1 p-large-bold ">
                    <u>{CONTACT.address},</u> <br />
                    <u>{CONTACT.zip},</u> <u>{CONTACT.city}, </u>
                    <br />
                    <u>{CONTACT.country}</u>
                  </p>
                </a>
                <p className="mb-1 mb-md-3 p-large-bold">Contact us:</p>

                <a href="tel:+4531110006">+45 31 11 00 06</a>
                <p className="mb-3 mb-md-6">connect@studiesandme.com</p>
                <div className="d-flex mb-4">
                  <a href="https://www.linkedin.com/company/studiesandme" target="_blank" rel="noopener noreferrer">
                    <img className="mr-4" src={linkedin} alt="" />
                  </a>

                  <a
                    href="https://www.facebook.com/participate.studiesandme/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="mr-4" src={facebook} alt="" />
                  </a>

                  <a
                    href="https://instagram.com/studiesandme?igshid=6xnhb3hl1exa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="mr-4" src={insta} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-7 mb-6 mt-md-0 mb-md-0 h-100">
                <Link to="/contact">
                  <p className="mb-4 p-large-bold">Contact</p>
                </Link>
                <Link to="/terms">
                  <p className="mb-4 p-large-bold">Terms of Use</p>
                </Link>

                <Link to="/privacy">
                  <p className="mb-4 p-large-bold">Privacy Policy</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row justify-content-end" style={{}}>
              <div className="col-12 col-md-6">
                <h5 className="font-weight-bold">Want to work with us?</h5>
                <p className="mb-1">See vacant positions within Sanos group or send an unsolicited job application.</p>
                <a  href="https://sanos.career.emply.com/vacant-positions">
                  <p className="font-weight-bold"> <u>Click here</u></p>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
