export const BASE_URL = process.env.GATSBY_STRAPI_URL
console.log(' constant > BASE_URL = ', BASE_URL)

export const CONTACT = {
  email: 'connect@studiesandme.com',
  address: 'Hauser Pl. 20, 1',
  zip: 'DK-1127',
  city: 'Copenhagen K',
  country: 'Denmark',
}

export const LINK_TO_PARTICIPATE = 'https://participate.studiesandme.com/'

export const NEWS = [
  {
    text: 'Studies&Me establishes a unique collaboration with Glostrup Pharmacy',
    linkTxt: 'medwatch.dk',
    link: 'https://medwatch.dk/Medicinal___Biotek/article12407461.ece',
  },
  {
    text: 'Digitale værktøjer kan centralisere medicinforsøg',
    linkTxt: 'finans.dk',
    link: 'https://finans.dk/erhverv/ECE12187607/digitale-vaerktoejer-kan-centralisere-medicinforsoeg/',
  },
  {
    text: 'Digitalisering af dyre lægemiddelforsøg kan spare medicinalindustrien for milliarder',
    linkTxt: 'finans.dk',
    link:
      'https://finans.dk/erhverv/ECE12183885/digitalisering-af-dyre-laegemiddelforsoeg-kan-spare-medicinalindustrien-for-milliarder/',
  },
  {
    text: 'Medicinforsøg med smartphone-billeder: »Hele situationen med covid-19 har gjort os ekstremt relevante«',
    linkTxt: 'finans.dk',
    link:
      'https://finans.dk/erhverv/ECE12104017/medicinforsoeg-med-smartphonebilleder-hele-situationen-med-covid19-har-gjort-os-ekstremt-relevante/',
  },

  {
    text: 'Patienter skal medudvikle fremtidens behandlinger hjemme fra dagligstuen',
    linkTxt: 'jyllands-posten.dk',
    link:
      'https://jyllands-posten.dk/debat/kronik/ECE11448022/patienter-skal-medudvikle-fremtidens-behandlinger-hjemme-fra-dagligstuen/ ',
  },
]

export const PUBLICATIONS = [
  {
    text: 'High adherence and low dropout rate... ',
    linkTxt: 'pubmed.gov',
    link: 'https://pubmed.ncbi.nlm.nih.gov/32614886/',
  },
  {
    text: 'Virtual Clinical Trials: Perspectives in Dermatology.',
    linkTxt: 'pubmed.gov',
    link: 'https://pubmed.ncbi.nlm.nih.gov/32126560/',
  },
  {
    text: 'COVID-19 spurs the need for remote assessments in clinical research',
    linkTxt: 'medium.com',
    link: 'https://medium.com/leoilab/covid-19-spurs-the-need-for-remote-assessments-in-clinical-research-5e25ee9ca5bc',
  },
  {
    text: 'COVID-19 pandemic pushes clinical trials to go virtual',
    linkTxt: 'medium.com',
    link: 'https://medium.com/leoilab/covid-19-pandemic-pushes-clinical-trials-to-go-virtual-eaa7b7135360',
  },
  {
    text: 'Smart button and app to help atopic dermatitis patients in clinical study',
    linkTxt: 'pubmed.gov',
    link: 'https://pubmed.ncbi.nlm.nih.gov/32099541/ ',
  },
]
