import React from 'react'
import '../styles/styles.scss'
import Footer from './Footer'
import logo from '../assets/sandme-logo.svg'
import { Link } from 'gatsby'
import MobileNavigation from './MobileNavigation'
import { withBreakpoints } from 'react-breakpoints'

if (process.env.NODE_ENV !== 'production') {
  import('../styles/size-debug.scss').then((something) => {})
}

const Layout = (props) => {
  const { data, breakpoints, currentBreakpoint } = props

  const isMobile = breakpoints[currentBreakpoint] > breakpoints.sm ? false : true

  return (
    <div className="d-flex flex-column position-relative">
      <MobileNavigation isMobile={isMobile}></MobileNavigation>
      <div
        className="d-none col-12 d-md-flex bg-white justify-content-between position-fixed"
        style={{ zIndex: 50, borderBottom: '1px solid rgba(0,0,0,.1)' }}
      >
        <Link to="/">
          <img src={logo} className="my-4 ml-3" style={{ height: '1.4rem' }} alt="" />
        </Link>
        <div className="d-flex align-items-center mr-2">
          {/*<a href="http://participate.studiesandme.com/" target="_blank" rel="noopener noreferrer">
            <p className="font-weight-bold mb-0 mr-4" style={{ color: '#050709' }}>
              Participate
            </p>
          </a>*/}

          <Link to="/contact" className="mr-4">
            <p className="font-weight-bold mb-0" style={{ color: '#050709' }}>
              Contact
            </p>
          </Link>
          {/*<Link to="/requestademo">
            <p className="font-weight-bold mb-0" style={{ color: '#050709' }}>
              Request a demo
            </p>
          </Link>*/}
        </div>
      </div>

      {props.children}

      <Footer></Footer>
    </div>
  )
}

export default withBreakpoints(Layout)
