/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-07-2020.
 */
import React, { useEffect, useRef, useState } from 'react'
import menuSvg from '../assets/menu.svg'
import closeSvg from '../assets/close.svg'

import { gsap, Strong } from 'gsap'
import { CONTACT, LINK_TO_PARTICIPATE } from '../constant'
import { Link } from 'gatsby'

import linkedin from '../assets/linkedIn-white.svg'
import facebook from '../assets/facebook-white.svg'
import insta from '../assets/instagram-white.svg'
import { useScroll } from 'react-use-gesture'
import { win } from '../utils/browserMock'

const MobileNavigation = ({ props }) => {
  const mobileRef = useRef()
  const cornerMenu = useRef()

  const [menuState, setMenuState] = useState(false)

  const toggle = () => {
    setMenuState(!menuState)
  }

  //const minScrollToHide = 200
  useScroll(
    ({ xy, direction, offset: [ox, oy], movement: [mx, my] }) => {
      //console.log(' MobileNavigation > direction = ', direction, my, oy)

      if (direction[1] === 1 && my > 200 && oy > 450) {
        gsap.to(cornerMenu.current, 0.5, { x: 50, y: -100, ease: 'Expo.easeOut' })
      }

      if (direction[1] === -1 && my < -150) {
        gsap.to(cornerMenu.current, 0.5, { x: 0, y: 0, ease: 'Expo.easeOut' })
      }

      if (oy < 450) {
        gsap.to(cornerMenu.current, 0.5, { x: 0, y: 0, ease: 'Expo.easeOut' })
      }
    },
    { domTarget: win }
  )

  useEffect(() => {
    gsap.to(mobileRef.current, 0.5, { autoAlpha: menuState ? 1 : 0, ease: 'Expo.easeOut' })
  })

  return (
    <div
      className="container-fluid position-fixed w-100 d-block d-md-none "
      style={{ zIndex: 200, touchAction: 'none' /* background: '#28355a', height: 70 */ }}
    >
      <div className="row" style={{}}>
        <div
          ref={cornerMenu}
          onClick={toggle}
          className="col-12 d-flex justify-content-between position-relative"
          style={{ zIndex: 20 }}
        >
          <div className="position-absolute" style={{ top: -2, right: 0 }}>
            <svg width="85" height="86" viewBox="0 0 85 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M85 86C38.0558 86 0 47.7203 0 0.5C0 0.5 38.0558 0.5 85 0.5L85 86Z" fill="#121533" />
            </svg>
          </div>
          <div></div>

          <div className="position-relative pr-5 mt-4">
            <img
              className=" position-absolute"
              style={{ top: 0, left: 0, width: 'auto', height: '1.2rem', opacity: menuState ? 0 : 1 }}
              src={menuSvg}
              alt=""
            />
            <img
              className="position-absolute"
              style={{ top: -8, left: -8, width: 'auto', height: '2.3rem', opacity: menuState ? 1 : 0 }}
              src={closeSvg}
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        ref={mobileRef}
        className="container-fluid vh-100 w-100 position-absolute"
        style={{ opacity: 0, visibility: 'hidden', top: 0, left: 0, background: 'red' }}
      >
        <div className="row h-100" style={{}}>
          <div className="col-12 d-flex flex-column justify-content-center justify-content-around bg-primary">
            <div className="pl-4">
              <p>
                <Link
                  to="/"
                  className="text-white"
                  style={{ fontSize: '1.25rem' }}
                  activeStyle={{
                    opacity: 0.8,
                  }}
                >
                  Home
                </Link>
              </p>
              {/* <a href={LINK_TO_PARTICIPATE} target="_blank" rel="noopener noreferrer">
                <p style={{ fontSize: '1.25rem' }} className="text-white">
                  Participate
                </p>
              </a>*/}

              <p>
                <Link
                  to="/contact"
                  className="text-white"
                  style={{ fontSize: '1.25rem' }}
                  activeStyle={{
                    opacity: 0.8,
                  }}
                >
                  Contact
                </Link>
              </p>

              {/* <p>
                <Link
                  to="/requestademo"
                  className="text-white"
                  style={{ fontSize: '1.25rem' }}
                  activeStyle={{
                    opacity: 0.8,
                  }}
                >
                  Request a demo
                </Link>
              </p>*/}

              {/*<p className="text-white lead">Contact</p>*/}
            </div>
            <div className="px-4 mb-4">
              {/*<div className="w-100 mb-4" style={{ height: 1, background: 'white', opacity: 0.8 }}></div>*/}
              {/*<p className="text-white mb-0 ">
                <small>Contact us:</small>
              </p>
*/}
              <p className="text-white mb-1 pr-md-2 " style={{ fontSize: '.85rem', opacity: 0.8 }}>
                {CONTACT.address}, {CONTACT.zip},
                <br />
                {CONTACT.city}
                <br />
                {CONTACT.country}
              </p>

              <div className="d-flex mb-2 mt-3">
                <a href="https://www.linkedin.com/company/studiesandme" target="_blank" rel="noopener noreferrer">
                  <img className="mr-4 social-white-icon" src={linkedin} alt="" />
                </a>

                <a href="https://www.facebook.com/participate.studiesandme/" target="_blank" rel="noopener noreferrer">
                  <img className="mr-4" src={facebook} alt="" />
                </a>

                <a
                  href="https://instagram.com/studiesandme?igshid=6xnhb3hl1exa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mr-4" src={insta} alt="" />
                </a>
              </div>
              <Link
                className="mr-3"
                activeStyle={{ opacity: 0.5 }}
                style={{ color: 'white', fontSize: '.8rem' }}
                to={'/terms'}
              >
                <u>Terms</u>
              </Link>
              <Link className="mr-3" style={{ color: 'white', fontSize: '.8rem' }} to={'/privacy'}>
                <u>Privacy Policy</u>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MobileNavigation
